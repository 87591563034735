import React, { useRef, useState } from "react";

import Button, { IconButton } from "@atlaskit/button/new";

import CrossIcon from "@atlaskit/icon/glyph/cross";

import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition } from "@atlaskit/modal-dialog";
import { Box, Flex, Grid, xcss } from "@atlaskit/primitives";
import TextArea from "@atlaskit/textarea";

import { useDeleteStoreMemo } from "@/apis/stores/deleteStoreMemo";
import { usePatchStoreMemo } from "@/apis/stores/patchStoreMemo";
import { usePostStoreMemo } from "@/apis/stores/postStoreMemo";

interface ModalProps {
  storeId: number;
  storeOwner: string;
  storeName: string;
  comments:
    | []
    | {
        comment: string;
        createdAt: string;
        id: number;
        updatedAt: string;
      }[];
  onClose: () => void;
}

const MemoModal = ({ storeId, storeOwner, storeName, comments, onClose }: ModalProps) => {
  const memoRef = useRef<HTMLTextAreaElement | null>(null);
  const editMemoRef = useRef<HTMLTextAreaElement | null>(null);
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [isEditComment, setIsEditComment] = useState<{ show: boolean; id: number | null }>({ show: false, id: null });
  const [isOpenAlert, setIsOpenAlert] = useState<{
    type: "danger" | "dirty" | null;
    show: boolean;
    id: number | null;
  }>({
    type: null,
    show: false,
    id: null,
  });

  const mutation = usePostStoreMemo(storeId);
  const patchStoreMemo = usePatchStoreMemo(storeId);
  const deleteMutation = useDeleteStoreMemo(storeId);

  function formatDate(dateString: string) {
    const date = new Date(dateString);

    const pad = num => String(num).padStart(2, "0");

    const year = String(date.getFullYear()).slice(-2);
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());

    return `${year}.${month}.${day} ${hours}:${minutes}`;
  }

  const handleDeleteMemo = async (commentId: number) => {
    await deleteMutation.mutateAsync(commentId);
    setIsOpenAlert({ type: null, show: false, id: null });
  };

  const handleSaveMemo = async () => {
    if (memoRef.current) {
      await mutation.mutateAsync(memoRef.current.value);
      memoRef.current.value = "";
    }
    setIsFocus(false);
  };

  const handleEditMemo = async (commentId: number) => {
    if (editMemoRef.current) {
      await patchStoreMemo.mutateAsync({ commentId, memo: editMemoRef.current.value });
      setIsEditComment({ show: false, id: null });
    }
  };

  const handleCloseModal = () => {
    if (memoRef.current?.value || editMemoRef.current?.value || isEditComment.show) {
      setIsOpenAlert({ type: "dirty", show: true, id: null });
      return;
    }
    onClose();
  };

  const handleCancelMemo = () => {
    if (memoRef.current) {
      memoRef.current.value = "";
    }
    setIsFocus(false);
  };

  const handleEditClick = (commentId: number) => {
    setIsFocus(false);
    memoRef.current.blur();
    setIsEditComment({ show: true, id: commentId });
  };

  // useEffect(() => {
  //   if (memoRef.current) {
  //     memoRef.current.blur();
  //   }
  // }, [comments]);

  return (
    <ModalTransition>
      <Modal onClose={handleCloseModal} width="medium" autoFocus={false}>
        <ModalHeader>
          <Grid gap="space.200" templateAreas={["title close"]} xcss={gridStyles}>
            <Flex xcss={closeContainerStyles} justifyContent="end">
              <IconButton
                appearance="subtle"
                icon={CrossIcon}
                label="Close Modal"
                onClick={handleCloseModal}
                spacing="compact"
              />
            </Flex>
            <Flex xcss={titleContainerStyles} justifyContent="start">
              <ModalTitle>
                <Box
                  style={{
                    fontSize: "14px",
                    color: "#626F86",
                    fontWeight: 500,
                  }}
                >
                  {storeOwner} • {storeName}
                </Box>
                <Box style={{ fontSize: "20px", fontWeight: "bold", color: "#172B4D" }}>가게 메모</Box>
              </ModalTitle>
            </Flex>
          </Grid>
        </ModalHeader>
        <ModalBody>
          <Box style={{ width: "100%" }}>
            <TextArea
              autoFocus={false}
              ref={memoRef}
              id="area"
              resize="none"
              maxHeight="80px"
              name="area"
              placeholder="메모를 입력하세요."
              onFocus={() => setIsFocus(true)}
              onBlur={() => {
                if (!memoRef.current?.value && isFocus) {
                  setIsFocus(false);
                }
              }}
              style={{
                border: isFocus ? "1px solid #3979FF" : "2px solid #091E4224",
                outline: "none",
              }}
            />
            {isFocus && (
              <Box style={{ paddingTop: "6px" }}>
                <Button appearance="primary" spacing="compact" onClick={handleSaveMemo}>
                  저장
                </Button>
                <Button appearance="subtle" spacing="compact" onClick={handleCancelMemo}>
                  취소
                </Button>
              </Box>
            )}
          </Box>
          <Box style={{ padding: "32px 0 0 0", display: "flex", flexDirection: "column", gap: "20px" }}>
            {comments.length > 0 &&
              comments.map(comment =>
                comment.id === isEditComment.id ? (
                  <Box key={comment.id}>
                    <Box style={{ width: "100%" }}>
                      <TextArea
                        ref={editMemoRef}
                        id={comment.id.toString()}
                        resize="none"
                        maxHeight="80px"
                        name="area"
                        placeholder="메모를 입력하세요."
                        defaultValue={comment.comment}
                        onFocus={() => {
                          editMemoRef.current.style.border = "1px solid #3979FF";
                        }}
                        onBlur={() => {
                          editMemoRef.current.style.border = "2px solid #091E4224";
                        }}
                        style={{
                          border: "2px solid #091E4224",
                          outline: "none",
                        }}
                      />
                      <Box style={{ paddingTop: "6px" }}>
                        <Button appearance="primary" spacing="compact" onClick={() => handleEditMemo(comment.id)}>
                          저장
                        </Button>
                        <Button
                          appearance="subtle"
                          spacing="compact"
                          onClick={() => setIsEditComment({ show: false, id: null })}
                        >
                          취소
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box key={comment.id}>
                    <Box style={{ display: "flex", gap: "4px" }}>
                      <Box style={{ color: "#44546F", fontSize: "14px" }}>{formatDate(comment.createdAt)} 생성</Box>
                      {formatDate(comment.createdAt) !== formatDate(comment.updatedAt) && (
                        <>
                          <Box style={{ color: "#626F86", fontSize: "14px" }}>•</Box>
                          <Box style={{ color: "#44546F", fontSize: "14px" }}>{formatDate(comment.updatedAt)} 수정</Box>
                        </>
                      )}
                    </Box>
                    <Box style={{ color: "#172B4D", fontSize: "14px", padding: "8px 0 10px", whiteSpace: "pre-wrap" }}>
                      {comment.comment}
                    </Box>
                    <Box style={{ display: "flex", gap: "4px" }}>
                      <Box
                        style={{ color: "#44546F", fontSize: "14px", fontWeight: 500, cursor: "pointer" }}
                        onMouseDown={e => {
                          e.preventDefault(); // onBlur보다 우선 처리
                          handleEditClick(comment.id);
                        }}
                      >
                        편집
                      </Box>
                      <Box style={{ color: "#44546F", fontSize: "14px" }}>•</Box>
                      <Box
                        style={{ color: "#44546F", fontSize: "14px", fontWeight: 500, cursor: "pointer" }}
                        onClick={() => setIsOpenAlert({ type: "danger", show: true, id: comment.id })}
                      >
                        삭제
                      </Box>
                    </Box>
                  </Box>
                ),
              )}
          </Box>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
      {isOpenAlert.show && isOpenAlert.type === "danger" && (
        <Modal width="small" onClose={() => setIsOpenAlert({ type: null, show: false, id: null })}>
          <ModalHeader>
            <ModalTitle appearance="danger">이 메모를 삭제하시겠습니까?</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Box
              style={{
                fontSize: "14px",
                color: "#44546F",
              }}
            >
              삭제하면 영구적으로 제거됩니다.
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button appearance="danger" onClick={() => handleDeleteMemo(isOpenAlert.id)}>
              삭제
            </Button>
            <Button appearance="subtle" onClick={() => setIsOpenAlert({ type: null, show: false, id: null })}>
              취소
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {isOpenAlert.show && isOpenAlert.type === "dirty" && (
        <Modal width="small" onClose={() => setIsOpenAlert({ type: null, show: false, id: null })}>
          <ModalHeader>
            <ModalTitle appearance="warning">저장하지 않고 나가시겠습니까?</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Box
              style={{
                fontSize: "14px",
                color: "#44546F",
              }}
            >
              작성 중인 내용이 있습니다. 저장하지 않고 페이지를 벗어날 경우 지금까지 작성한 내용이 사라집니다.
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button appearance="warning" onClick={onClose}>
              나가기
            </Button>
            <Button appearance="subtle" onClick={() => setIsOpenAlert({ type: null, show: false, id: null })}>
              취소
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default MemoModal;

const gridStyles = xcss({
  width: "100%",
});

const closeContainerStyles = xcss({
  gridArea: "close",
});

const titleContainerStyles = xcss({
  gridArea: "title",
});
