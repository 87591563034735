import { useQuery, useQueryClient } from "react-query";

import { instance } from "../instance";

interface MismatchedWithdrawalProps {
  currentPage: number;
  previousPage?: number;
  nextPage?: number;
  lastPage: number;
  totalCount: number;
  fetchWithdrawals: "NOT_STARTED" | "IN_PROGRESS" | "DONE" | "FAILED";
  calculateUnderOver: "NOT_STARTED" | "IN_PROGRESS" | "DONE" | "FAILED";
  results: {
    id: number;
    title: string;
    username: string;
    actualWithdrawalAmount: number;
    expectedWithdrawalAmount: number;
    differenceAmount: number;
  }[];
}

const getMismatchedWithdrawals = async ({ page = 1, cfaAccounts }: { page?: number; cfaAccounts?: string }) => {
  const { data } = await instance({
    method: "get",
    url: "/v2/admin/stores/mismatched-withdrawals",
    params: {
      page,
      cfa_accounts: cfaAccounts,
    },
  });

  return data;
};

export const useGetMismatchedWithdrawal = ({ page = 1, cfaAccounts }: { page?: number; cfaAccounts?: string }) => {
  const { data, isLoading, refetch } = useQuery<MismatchedWithdrawalProps, Error>(
    ["MismatchedWithdrawal", page, cfaAccounts],
    () => getMismatchedWithdrawals({ page, cfaAccounts }),
  );
  return {
    data,
    isLoading,
    refetch,
  };
};

const getAllMismatchedWithdrawals = async () => {
  const { data } = await instance({
    method: "get",
    url: "/v2/admin/stores/mismatched-withdrawals",
  });

  return data;
};

export const useGetAllMismatchedWithdrawal = ({
  currentPage = 1,
  checkedCfaString,
}: {
  currentPage?: number;
  checkedCfaString?: string;
}) => {
  const queryClient = useQueryClient();
  const { data, isLoading, refetch } = useQuery<MismatchedWithdrawalProps, Error>(
    ["MismatchedAllWithdrawal"],
    () => getAllMismatchedWithdrawals(),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["MismatchedWithdrawal", currentPage, checkedCfaString]);
      },
      onError: error => {
        console.log("onError", error);
      },
    },
  );
  return {
    data,
    isLoading,
    refetch,
  };
};
