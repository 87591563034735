import React from "react";

import styled from "styled-components";

const CheckBoxWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  div {
    display: flex;
    gap: 2px;
    align-items: center;
  }
`;

const CheckDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Check = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 2px;
  border: 1px solid aaa;
  color: #fff;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 2;
  background: ${(props: { _act: boolean }) => (props._act ? "#fff" : "#3486ff")};
  border: 1px solid #3486ff;
`;

interface CheckboxInterface {
  _checkList: { key: string; act: boolean }[];
  _setCheckList: (checkBox: { key: string; act: boolean }[]) => void;
}

const CheckBox = ({ _checkList, _setCheckList }: CheckboxInterface) => {
  const itemClickHandler = (key: string) => {
    if (key === "전체") {
      const allAct = _checkList.find(item => item.key === "전체");
      const allCheckList = _checkList.map(item => {
        return { ...item, act: !allAct.act };
      });
      _setCheckList(allCheckList);
      return;
    }

    const changeCheckList = _checkList.map(item => {
      if (item.key === key) return { ...item, act: !item.act };
      return item;
    });
    _setCheckList(changeCheckList);
  };

  return (
    <CheckBoxWrapper>
      {_checkList.map(item => (
        <CheckDiv onClick={() => itemClickHandler(item.key)} key={item.key}>
          <div>{item.key}</div>
          {/* <div>{item.key}</div> */}
          <Check _act={item.act}>{!item.act && "✓"}</Check>
        </CheckDiv>
      ))}
    </CheckBoxWrapper>
  );
};

export default CheckBox;
